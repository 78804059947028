import { FunctionComponent, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/styles/index.scss";

const AgeGate = lazy(() => import("./app/AgeGate"));
const PrlNpl = lazy(() => import("./app/PrlNpl"));
const Project2 = lazy(() => import("./app/Project2"));
const CC = lazy(() => import("./app/CC"));

const urls = {
  ageGate: "/age-gate",
  prlNpl: "/prl-npl",
  project2: "/project2",
  cc: "/cc",
};

const App: FunctionComponent = () => {
  return (
    <Routes>
      <Route path={urls.ageGate} element={<AgeGate />} />
      <Route path={urls.prlNpl} element={<PrlNpl />} />
      <Route path={urls.project2} element={<Project2 />} />
      <Route path={urls.cc} element={<CC />} />
    </Routes>
  );
};

export default App;
